@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Black.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-ExtraBold.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-SemiBold.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Light.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-ExtraLight.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Thin.woff') format('woff2'),
    url('../assets/fonts/Inter/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend Deca';
  src: url('../assets/fonts/Lexend_Deca/LexendDeca-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/Lexend_Deca/LexendDeca-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('../assets/fonts/Roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
